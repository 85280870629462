<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      배송지 관리<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_17">
      <div class="sect_txt">
        · 배송지는 최대 10개까지 등록/관리할 수 있습니다.
      </div>
      <div class="sect_list">
        <div class="box" v-for="list in shppingList" :key="list.address_seq">
          <div class="top">
            <div class="left">
              {{ list.recipient_user_name }}({{
                list.address_description
              }})<span v-if="list.default === 'Y'">기본배송지</span>
            </div>
            <div class="right">
              <router-link
                :to="`/mypage/shipping_address_update/${list.address_seq}`"
                class="black"
                >수정</router-link
              ><router-link
                to=""
                @click.native="deleteShipping(list.address_seq)"
                class="gray"
                >삭제</router-link
              >
            </div>
          </div>
          <div class="bottom">
            <p>
              {{ list.recipient_address }}
              {{ list.recipient_address_detail }} ({{ list.recipient_zipcode }})
            </p>
            <p class="my_shipping_list">
              <span>{{ list.recipient_user_name }}</span
              ><span>{{ list.recipient_cellphone }}</span
              ><span>{{ checkPhone(list.recipient_phone) }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div v-if="shppingList.length < 10">
        <router-link
          to=""
          @click.native="$router.push('/mypage/shipping_address_reg')"
          >배송지 새로 등록하기</router-link
        >
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getShippingList();
  },
  computed: {
    shppingList() {
      return this.$store.state.mypage.listArray;
    },
  },
  methods: {
    async getShippingList() {
      await this.$store.dispatch("mypage/getShippingList");
    },
    deleteShipping(address_seq) {
      this.$confirm("배송지를 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/deleteShipping", {
            address_seq,
          });
          this.$toast.default(this.$store.state.mypage.msg);
        })
        .catch(() => {
          return false;
        });
    },
    checkPhone(str) {
      if (str) {
        if (str.replace(/-/g, "")) {
          return str;
        }
        return "";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.my_shipping_list {
  position: relative;
  span {
    margin-right: 5px;
  }
  span:last-child {
    position: absolute;
    right: 0;
    margin-right: 0;
  }
}
</style>
